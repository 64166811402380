import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
//import "primevue/resources/themes/arya-blue/theme.css";
import "primevue/resources/primevue.min.css";

import "primevue/resources/themes/aura-light-green/theme.css";
import "primevue/resources/primevue.min.css"; /* Deprecated */
import "primeicons/primeicons.css";

import PrimeVue from "primevue/config";

import Vue3ConfirmDialog from "@zapadale/vue3-confirm-dialog";

createApp(App)
  .use(router)
  .use(store)
  .use(PrimeVue, { ripple: true })
  .use(Vue3ConfirmDialog)
  //.component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
