import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL + "/api/";

class UserService {
  getPublicContent() {
    return axios.get(API_URL + "hello/home/", {
      headers: authHeader(),
    });
  }

  getImages(params) {
    const queryParams = params
      ? Object.keys(params)
          .map(
            (k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k])
          )
          .join("&")
      : "";

    return axios.get(API_URL + "images/?" + queryParams, {
      headers: authHeader(),
    });
  }

  getImageColumn(params) {
    /* const queryParams = params
             ? Object.keys(params)
                 .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                 .join('&')
             : '';*/

    return axios.get(API_URL + "edit-image/" + params.id, {
      headers: authHeader(),
    });
  }

  setImage(formData) {
    return axios({
      method: "post",
      url: API_URL + "upload-img/",
      data: formData,
      headers: authHeader(),
    });

    /*return axios.post(API_URL + 'upload-img/', {
            data: formData,
            headers: authHeader()
        });*/
  }

  updateImage(formData) {
    return axios({
      method: "post",
      url: API_URL + "update-img/" + formData.type + "/" + formData.id,
      data: formData,
      headers: authHeader(),
    });
  }

  deleteImage(formData) {
    return axios({
      method: "post",
      url: API_URL + "delete-img/" + formData.id,
      data: formData,
      headers: authHeader(),
    });
  }

  confirmImage(formData) {
    return axios({
      method: "post",
      url: API_URL + "confirm-img/" + formData.id,
      data: formData,
      headers: authHeader(),
    });
  }

  getModeratorBoard() {
    return axios.get(API_URL + "mod", {
      headers: authHeader(),
    });
  }

  getAdminBoard() {
    return axios.get(API_URL + "admin", {
      headers: authHeader(),
    });
  }

  setStyleError(text) {
    return '<div class="alert alert-danger" role="alert"> ' + text + " </div>";
  }

  setStyleSuccess(text) {
    return '<div class="alert alert-success" role="alert"> ' + text + " </div>";
  }
}

export default new UserService();
