import {
    createWebHistory,
    createRouter
} from "vue-router";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";
// lazy-loaded
const Profile = () => import("./components/Profile.vue")
const BoardAdmin = () => import("./components/BoardAdmin.vue")
const BoardModerator = () => import("./components/BoardModerator.vue")
const Images = () => import("./components/Images.vue")
const EditImages = () => import("./components/EditImages.vue")

const routes = [{
        path: "/",
        name: "home",
        component: Home,
    },
    {
        path: "/home",
        component: Home,
    },
    {
        path: "/login",
        component: Login,
    },
    {
        path: "/profile",
        name: "profile",
        // lazy-loaded
        component: Profile,
    },
    {
        path: "/images",
        name: "images",
        // lazy-loaded
        component: Images,
    },
    {
        path: "/edit-image/:id",
        name: "edit_image",
        // lazy-loaded
        component: EditImages,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});
/*router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/register', '/home'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});*/
export default router;