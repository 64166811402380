<template>
  <div class="container">
    <div>
      <h1 class="mb-4">Загрузка фото</h1>
      <div class="mb-3">
        <Dropdown
          v-model="selectedType"
          :options="type"
          optionLabel="name"
          placeholder="Выберите тип"
          class="w-full"
          v-on:change="handleTypeChange"
        />
      </div>

      <div class="mb-3">
        <label for="formFileLg" class="form-label"
          >Выберите файл изображения</label
        >
        <input
          type="file"
          name=""
          id=""
          v-on:change="handleFileChange"
          class="form-control form-control-sm"
        />
      </div>

      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label"
          >Название</label
        >

        <InputText
          id="name"
          v-model="name"
          class="form-control"
          aria-describedby="username-help"
        />
      </div>

      <div class="mb-3">
        <button type="button" class="btn btn-primary" v-on:click="uploadFile">
          Отправить
        </button>
      </div>
    </div>
    <header class="jumbotron">
      <h3 v-html="content"></h3>
    </header>
  </div>
</template>
<style>
.p-dropdown-items {
  padding-left: 1rem;
  padding-right: 1rem;
}
</style>
<script>
import UserService from "../services/user.service";
import axios from "axios";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import { ref } from "vue";

import "primevue/resources/themes/aura-light-green/theme.css";

export default {
  name: "Home",
  components: {
    InputText,
    Dropdown,
  },
  data() {
    return {
      content: "",
      file: null,
      name: "",
      selectedType: ref({ name: "Ухо", code: "ear" }),
      type: ref([
        { name: "Ухо", code: "ear" },
        { name: "Гортань", code: "larynx" },
      ]),
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    handleTypeChange(event) {
      this.name =
        this.selectedType.name +
        " " +
        this.currentUser.email +
        " " +
        Date.now();
    },
    handleFileChange(event) {
      this.file = event.target.files[0];
    },
    helloHome() {
      UserService.getPublicContent().then(
        (response) => {
          // this.content = response.data;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.content = UserService.setStyleError(this.content);

          if (
            error.response.data.authenticated != undefined &&
            error.response.data.authenticated == false
          ) {
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
          }
        }
      );
    },
    uploadFile() {
      let formData = new FormData();

      formData.append("file", this.file);
      formData.append("name", this.name);
      formData.append(
        "type",
        this.selectedType != undefined ? this.selectedType.code : "ear"
      );

      UserService.setImage(formData).then(
        (response) => {
          this.content = response.data;

          this.$router.push("/edit-image/" + this.content.id);
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.content = UserService.setStyleError(this.content);

          /*if(error.response.data.authenticated != undefined && error.response.data.authenticated === false){
              location.href = '/logout';
            }*/
        }
      );

      // axios.post('http://localhost:5000/api/upload-img/', formData)  // Отправить formData на сервер с помощью AJAX-запроса
    },
  },
  mounted() {
    document.title = "Загрузка фото";

    this.helloHome();

    if (!this.currentUser) {
      this.$router.push("/login");
    }
    this.name =
      this.selectedType.name + " " + this.currentUser.email + " " + Date.now();
    /* UserService.getPublicContent().then(
        (response) => {
          this.content = response.data;
        },
        (error) => {
          this.content =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();
          this.content = UserService.setStyleError(this.content);
        }
    );*/
  },
};
</script>
<style scoped>
.file-upload {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.file-upload .file-upload__area {
  width: 600px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #ccc;
  margin-top: 40px;
}
</style>
